<template>
  <div>
    <Wrapper>
      <Sidebar slot="sidebar"/>
      <Navbar slot="navbar"/>
      <Footer slot="footer"/>
      <div slot="content">
        <!-- <input type="text" v-model="filters.name.value"> -->
        <!-- <div class="pre-loader text-center" v-if="preLoader">
                    <span class="loader"></span>
        </div>-->
        <div class="pre-loader text-center" v-if="preLoader">
                    <span class="loader"></span>
                </div>


<!-- <div class="mt-4"></div> -->
<div style="max-width:700px; margin:4% 3%"     v-if="windowWidth < 768">
      <h3 class="mt-2 mb-2">  <img src="../../../assets/images/back_mob.png" loading="lazy" alt="" height="18" 
       class="pr-2  mb-1"  @click="goBack"> {{dollarProduct.name}}</h3>
      <!-- <p class="mb-3">Imagine Africa</p> -->
   
</div>
<div class="invest-container" v-if="!preLoader">
<div style="padding:20px" v-if="windowWidth > 767">
      <img
                      src="../../../assets/images/back1.png"
                      height="50"
                      class="point-cursor"
                    @click="goBack"
                    >
</div>


<div style="max-width:700px; margin:0 auto">
      <h3 class="mt-4 mb-4"  v-if="windowWidth > 767"> {{dollarProduct.name}}</h3>
     
      <div style="min-height:40px ;display:flex; flex-wrap:wrap; justify-content:space-between;" class="mt-4">
          <div  class="mr-4 flex-40-100">
              <h5 style="font-size:24px !important; font-wight:600">How long are you willing to hold for?</h5>
              <p style="color:#4F4F4F" class="pt-2 mb-4">Choose a period</p>
          </div>
          <div class="flex-60-100" style=" display:flex;;justify-content:space-between; flex-wrap:wrap " >
                <div style="flex-basis:47%; border:1px solid #0094FF; min-height:100px; border-radius: 10px; padding:15px" class="tenor-div mb-4"   v-for="tenor in dollarBondMaturityArray" :key="tenor.id" @click="saveMaturityDate(tenor)">
            <p  class="pb-2 grey-text">{{tenor.period}}</p>
            <p style=" font-size:16px"><b>{{tenor.numOfMonths}} months</b></p>
             <p style="color:#828282" class="pb-2 pt-2">{{tenor.numOfDays}} days</p>
             <p  class="pt-2 text-right" style="font-size:14px;font-weight:500">{{tenor.rate_range_low}}% - {{tenor.rate_range_high}}% Per Annum</p>
                </div>
              
          </div>
      </div>
 
</div>
</div>

      </div>
    </Wrapper>
  </div>
</template>

<script>
import Navbar from "../../partials/Navbar";
import Sidebar from "../../partials/Sidebar";
import Footer from "../../partials/Footer";
import Wrapper from "../../partials/Wrapper";
import trans from "../../../../translations";

import { commonJs } from "../mixins/commonJs.js";

import { mapState } from "vuex";

import moment from "moment";
import http from "../../../http/index";
import api from "../../../apis/index";
export default {
  mixins: [commonJs],
  data() {
    return {
      tran: {},
      minAmount: "",
      termsPrivacy: false,
      bookAmmount:'',
      inActivateBookingStatus: true,
    };
  },
  methods: {
    goBack() {
      this.$router.push("/dollar/invest");
    },
   

    saveMaturityDate(maturity){
  this.$store.commit("market/saveBondMaturityDate", maturity);
 this.$router.push("/dollar/summary");
    },

  

  },
  components: {
    Navbar,
    Footer,
    Sidebar,
    Wrapper
  },

  computed: {
    ...mapState({
      userBal: state => state.auth.userProfileInfo.balance,
         dollarBondMaturityArray: (state) =>
        state.market.dollarBondMaturityArray,
           bondData: (state) =>
        state.market.bondData,
    }),

  
  },
  mounted() {
    let heading = {
       main: this.tran.euro_bond
    };
    this.$store.dispatch("market/getEuroBondHistory");
     this.$store.dispatch("market/getEuroBondData");

     if(this.bondData == null){
        this.$router.push({
            path: "/dollar/invest",
         
          });
    }
  },

 
};
</script>

<style scoped>

.invest-container{

    background:#fff;
    min-height: 80vh;
    margin: 5%;
    margin-top:2%;
    border-radius: 10px;
    padding: 10px;
}

.tenor-div{
    cursor: pointer;
    transition: .4s;
}

.grey-text{

    color:#828282;
}

.tenor-div:hover{

background: #0094FF;
    transition: .4s;
    color:#fff !important;

}


.tenor-div:hover > p{

    color:#fff !important;
}
.flex-40-100{
    flex-basis:40%;
}

.flex-60-100{

    flex-basis:55%;
}
@media(max-width:1500px){
    
.invest-container{
    zoom:85%;
        min-height: 90vh;
}
}
@media(max-width:767px){
.flex-60-100, .flex-40-100{
    flex-basis:100% !important;
}
.invest-container{
      margin: 3%;
        min-height: 40vh;
}
}
</style>
