<template>
<div>
    <DollarTenor />
</div>
</template>

<script>
import DollarTenor from '../../components/dashboard/dollarAsset/DollarTenor.vue'
export default {
    components: {
        DollarTenor
    }
}
</script>

